import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import icon from '../images/modal/icon.svg'
import close from '../images/modal/close.svg'
import { LangContext } from "../context/langContext"
import mbackGold from '../images/modal/Back_gold.svg'
import mbackGrey from '../images/modal/Back_grey.svg'
import mnextGold from '../images/modal/Next_gold.svg'
import mnextGrey from '../images/modal/Next_grey.svg'
import backGold from '../images/modal/backYellow.png'
import backGrey from '../images/modal/backGrey.png'
import nextGold from '../images/modal/nextYellow.png'
import nextGrey from '../images/modal/nextGrey.png'
import { FormattedMessage } from "react-intl";
import Loader from '../components/loader'

const ProductModal = (props) => {

  const { modalData, onHide, nextProd, prevProd } = props;

  const { show, prod, next, prev } = modalData;

  const { lang } = useContext(LangContext);

  const baseUrl = "https://dashboard.almootamayezoon.com/assets/uploads/images/";

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false)
  }, [prod]);

  return (
    <Modal
      show = {show}
      onHide= {onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="d-none d-lg-block">
        <img src={close} alt="close" className="closeBtn" onClick={onHide} />
        <img src={icon} alt="icon" className="logo" />
        <div className="backBtn">
          <img src={prev?backGold:backGrey} alt="btn"  onClick={prevProd} />
        </div>
        <div className="nextBtn">
          <img src={next?nextGold:nextGrey} alt="btn" onClick={nextProd} />
        </div>
        <div className="row">
          <div className={lang==='en'?"col-6 leftCol text-left":"col-6 leftCol text-right"}>
            <p className="header1 yellow text-uppercase minHeight">{ lang==='en'? prod.en_name: prod.ar_name}</p>
            <div className="row mt-5">
              <div className="col-6 px-0">
                <p className="grey body lh"><FormattedMessage id="productCode" /></p>
                <p className="grey body lh"><FormattedMessage id="net" /></p>
                <p className="grey body lh"><FormattedMessage id="quantity" /></p>
              </div>
              <div className="col-6">
                <p className="grey header2">{prod.code}</p>
                <p className="grey header2">{prod.net}<FormattedMessage id="kg" /></p>
                <p className="grey header2">{prod.quantity==='0'?'---':prod.quantity}</p>
              </div>
            </div>
          </div>
          <div className="col-6 px-0">
            {!isLoaded && <Loader height="100%" />}
            <img src={baseUrl + prod.img_url} className={isLoaded? "d-block":"d-none"} alt="product" width="100%" height="100%"  onLoad={() => setIsLoaded(true)} />
          </div>
        </div>
      </Modal.Body>
      {/* mobile design */}
      <Modal.Body className="d-lg-none">
        <img src={close} alt="close" className="closeBtn" onClick={onHide} />
        <img src={icon} alt="icon" className="logo" />
        <div className="row">
          <div className="col-12 px-0">
            {!isLoaded && <Loader height="100%" />}
            <img src={baseUrl + prod.img_url} className={isLoaded? "d-block":"d-none"} alt="product" width="100%" height="100%" />
          </div>
          <div className={lang==='en'?"col-12 leftCol text-left":"col-12 leftCol text-right"}>
            <p className="header1 yellow text-uppercase">{ lang==='en'? prod.en_name: prod.ar_name}</p>
            <div className="row mt-5">
              <div className="col-6 px-0">
                <p className="grey body lh"><FormattedMessage id="productCode" /></p>
                <p className="grey body lh"><FormattedMessage id="net" /></p>
                <p className="grey body lh"><FormattedMessage id="quantity" /></p>
              </div>
              <div className="col-6">
                <p className="grey header2">{prod.code}</p>
                <p className="grey header2">{prod.net}<FormattedMessage id="kg" /></p>
                <p className="grey header2">{prod.quantity==='0'?'---':prod.quantity}</p>
              </div>
            </div>
          </div>
          <div className={lang==='en'?"col-6 text-center back":"col-6 text-center back order-4"}>
            <img src={prev?mbackGold:mbackGrey} alt="btn" className="backBtn" onClick={prevProd} />
          </div>
          <div className="col-6 text-center next">
            <img src={next?mnextGold:mnextGrey} alt="btn" className="nextBtn" onClick={nextProd} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProductModal;