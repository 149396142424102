import React, { useState, useEffect, useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import arrowDown from '../images/home/arrowDown.svg'
import Loader from '../components/loader'
import ProductModal from '../components/productModal'
import { LangContext } from "../context/langContext"
import { ProductsContext } from "../context/productsContext"
import Dropdown from 'react-bootstrap/Dropdown'
import { graphql } from "gatsby"
import Img from "gatsby-image"

const Products = ({data}) => {

  
  const { lang } = useContext(LangContext);
  const {categories, products, activeCatId, isLoading, changeActiveCatId } = useContext(ProductsContext)

  const baseUrl = "https://dashboard.almootamayezoon.com/assets/uploads/images/"

  const menuClickHandler = (id) => {
    changeActiveCatId(id);
  }
  

/////////// Modal Logic ///////////////////

  const [modalData, setModalData] = useState({
    show: false,
    prod: {},
    next: true,
    prev: true
  });

  const { prod } = modalData

  const toggleModal = (prod) => {
    setModalData({
      ...modalData,
      show: true,
      prod: {...prod},
    })
  }


  const nextProd = () => {
    let currentIndex = products.findIndex(x => x.id === prod.id);
    if(currentIndex === products.length-2){
      let nextProd = products[currentIndex+1];
      setModalData({
        ...modalData, prod: {...nextProd}, next: false
      })
    }
    else if(currentIndex < products.length-1) {
      let nextProd = products[currentIndex+1];
      setModalData({
        ...modalData, prod: {...nextProd}, next: true, prev: true
      })
    }
    else{
      setModalData({
        ...modalData, next: false
      })
    }
  }
  const prevProd = () => {
    let currentIndex = products.findIndex(x => x.id === prod.id);
    if(currentIndex === 1){
      let prevProd = products[currentIndex-1];
      setModalData({
        ...modalData, prod: {...prevProd}, prev: false
      })
    }
    else if(currentIndex !== 0){
      let prevProd = products[currentIndex-1];
      setModalData({
        ...modalData, prod: {...prevProd}, next:true, prev: true 
      })
    }
    else{
      setModalData({
        ...modalData, prev: false
      })
    }
  }
////////////////////////////////////////


  return (
    <Layout>
      <SEO title="Products" />
      <div className="arrowDown2 d-none d-lg-block">
        <img src={arrowDown} alt="arrow" width="100%"/>
      </div>
      <div className="prodContainer d-none d-lg-block">
        <div className="row justify-content-start">
          <div className="col-3 text-center prodMenu px-0">
            {/* <img src={lang==='en'?ProductsMenu:ProductsMenuAr} alt="" width="75%" /> */}
            <Img fluid={lang==='en'?data.ProductsMenu.childImageSharp.fluid:data.ProductsMenuAr.childImageSharp.fluid} alt="" style={{width:"75%", margin:"0 auto"}} />
            <div lang={lang} className="linksDiv white">
              {categories?.map(x =>  <p key={x.id} className={ x.id == activeCatId ? 'active' : '' } onClick={() => menuClickHandler(x.id)} >{ lang==='en'? x.en_name: x.ar_name}</p>)}
            </div>
          </div>
          <div className="col-8 prodCol">
            { isLoading && <Loader height="65%" />}
            { !isLoading &&
              <>
              <p className="header yellow">{lang==='en'? categories[activeCatId-1]?.en_name: categories[activeCatId-1]?.ar_name}</p>
              <div className="row productsRow">
                { products.map(prod =>
                  <div key={prod.id} className="col-3 px-0 text-center product" onClick={()=>toggleModal(prod)}>
                    <img src={baseUrl + prod.img_url} alt="products" width="75%" />
                    <p className="links brown my-5 text-capitalize">{ lang==='en'? prod?.en_name: prod?.ar_name}</p>
                  </div>
                )}
              </div>
              </>
            }
          </div>
        </div>
      </div>
      {/* mobile design */}
      <div className="smallProdContainer d-lg-none">
        { isLoading && <Loader height="65%" />}
        { !isLoading &&
          <>
          <Dropdown>
            <Dropdown.Toggle id="dropdown" as="p" className="headerTxt header4 yellow text-uppercase text-wrap">
              {lang==='en'? categories[activeCatId-1]?.en_name: categories[activeCatId-1]?.ar_name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {categories.map(x => <Dropdown.Item lang={lang} key={x.id}><p className={ x.id === activeCatId ? 'active' : '' } onClick={() => menuClickHandler(x.id)} >{ lang==='en'? x.en_name: x.ar_name}</p></Dropdown.Item>)}
            </Dropdown.Menu>
          </Dropdown>
          <div className="row productsRow">
            { products.map(prod =>
              <div key={prod.id} className="col-6 px-1 text-center product" onClick={()=>toggleModal(prod)}>
                <img src={baseUrl + prod.img_url} alt="products" width="100%" />
                <p className="links brown my-5">{ lang==='en'? prod.en_name: prod.ar_name}</p>
              </div>
            )}
          </div>
          </>
        }
      </div>
      <div className="spacer"></div>
      <ProductModal
        modalData={modalData}
        onHide={() => setModalData({ show:false, prod: {}, next: true, prev: true })}
        nextProd={() => nextProd()}
        prevProd={() => prevProd()}
      />
    </Layout>
  )
}

export default Products;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 75) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    ProductsMenu: file(relativePath: { eq: "products/ProductsMenu.png" }) {
      ...fluidImage
    }
    ProductsMenuAr: file(relativePath: { eq: "products/ProductsMenu_Arabic.png" }) {
      ...fluidImage
    }
  }
`;